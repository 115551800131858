import React, { useMemo, useRef, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TypeAnimation } from "react-type-animation";
import logo from "../../assets/logo.png";

import { useForm ,Controller } from "react-hook-form";
import { DEFAULT_COOKIE_SETTER } from "../../helper/Cookie";

import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import postApi from "../../helper/postApi";
import ToasterGen from '../../helper/Toaster';
import toast from "react-hot-toast";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import useToggle from "../../helper/useToggle";


const Login = () => {
  const Employees: Array<string> = [
    "None",
    "1-10",
    "1-100",
    "1-1000",
    "1-2000",
  ];
  const [progress, setProgress] = useState(0);
  const push :any = useNavigate();

const [isPasswordHideShow, setPasswordHideShow] = useToggle(false);
const ChangePasswordHideShow = () => {
  setPasswordHideShow();
};
  // const colourStyles: any = {
  //   menuList: (styles: any) => ({
  //     ...styles,
  //     background: "papayawhip",
  //   }),
  //   option: (styles: any, { isFocused, isSelected }: any) => ({
  //     ...styles,
  //     background: isFocused
  //       ? "hsla(291, 64%, 42%, 0.5)"
  //       : isSelected
  //       ? "hsla(291, 64%, 42%, 1)"
  //       : undefined,
  //     zIndex: 1,
  //   }),
  //   menu: (base: any) => ({
  //     ...base,
  //     zIndex: 100,
  //   }),
  // };


  const formSchema = z.object({

    email: z.string().email().min(1, { message: "Email is Required!" }),

    password : z.any()
    

  });

  type LoginSchema = z.infer<typeof formSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },

    control,
  } = useForm<LoginSchema>({
    resolver: zodResolver(formSchema),
  });
  const onSubmit = async (payload: LoginSchema) => {
    
    
    setProgress(progress + 10)
  
    try
    {
   
    const {data,error}:any = await postApi("auth/getLogin", payload);
    setProgress((prevProgress) => progress + 20);
    
     
    if (data && data.message!="" && error.message  == undefined) {
     

      // localStorage.setItem(
      //   "Activation-Token",
      //   data && data?.activationToken?.token
      // );

       localStorage.setItem("user_data",JSON.stringify(data.user))
      await DEFAULT_COOKIE_SETTER("access_token",data.accessToken,false);
      await DEFAULT_COOKIE_SETTER("user",JSON.stringify({email: data.user.email,name:data.user.name}),false);
        

      toast.success(data.message);

      setProgress(progress + 30);

      push ("/dashboard");

      setProgress(100);
    }
    else{
      toast.error(error.message);
      setProgress(100);
    }

    }
    catch (err)
    {
      toast.error("Network Error!");


    }



  };

  return (


    
    <React.Fragment>

<LoadingBar
        color="rgb(95,126,220)"
        progress={progress}
        waitingTime={400}
        onLoaderFinished={() => {
          setProgress(0);
        }}
      />
      <ToasterGen></ToasterGen>



      <div className="grid grid-cols-1 lg:grid-cols-5 gap-1 lg:h-screen">
        <div className="col-span-2 lg:col-span-2">
          
          <div className=" lg:h-screen bg-[#c8ccc7] border-st  w-full lg:px-44">
            
            <div className="bg-[#c8ccc7] min-h-screen flex  items-center">
            <img src={logo} alt={"logo"} className="w-1/2 h-full lg:ml-4" />
        <br></br>
              <div className="font-Overpass text-2xl text-white text-center w-full">
                <h1>
                  <TypeAnimation
                    sequence={[
                      
                      "Manage Users",
                      1000,
                      "Update Settings ",
                      1000,
                      "Welcome to Admin",

                      1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: "1.3em", display: "inline-block" }}
                    className="font-Overpass"
                    repeat={Infinity}
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 lg:col-span-3 w-full bg-[#F9F9F9] h-full">
          <div className="min-h-screen flex items-center justify-center text-center">
            <div className="flex items-center justify-center bg-transparent">
              <div className="relative bg-white shadow-2xl rounded-2xl">
                <div className="flex flex-col justify-center p-8 md:p-14">
                  <div className="flex justify-center self-center items-center">
                    <form
                      className="w-full max-w-md"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h1 className="text-2xl font-Overpass text-gray-600 ">
                        Login to Admin
                      </h1>
                      <div className="py-1 w-full ">
                        <div className="py-1 w-full">
                          <span className="mb-2 text-md font-Overpass font-normal text-xs text-gray-600  font-Overpass leading-5 text-line">
                            Email
                          </span>
                          <input
                            {...register("email")}
                            id="email"
                            type="email"
                            className="w-full h-12 bg-transparent border-b-2 border-b-solid text-black border-b-gray-200 font-Overpass text-line placeholder:font-Overpass text-center placeholder:font-light focus:border-b-[#c8ccc7] focus:border-b-2 focus:border-b-solid placeholder:font-Overpass text-xs focus:outline-none"
                            placeholder="Email Address"
                            autoComplete="on"
                          />
                          {errors.email && (
                            <span className="text-red-500 block mt-1 text-Overpass text-xs">
                              {`${errors.email.message}`}
                            </span>
                          )}
                        </div>

                        <div className="py-1 w-full">
                        <span className="mb-2 text-md font-Overpass font-normal text-xs text-gray-600  font-Overpass leading-5 text-line">
                        Password
                      </span>
                      <div className="flex items-center rounded-xl bg-transparent relative">
                        <input
                          id="password"
                          {...register("password")}
                          autoComplete="on"
                          type={
                            isPasswordHideShow === false ? "password" : "text"
                          }
                          className="w-full h-12 bg-transparent border-b-2 border-b-solid text-black border-b-gray-200 font-Overpass text-line placeholder:font-Overpass text-center placeholder:font-light focus:border-b-[#c8ccc7] focus:border-b-2 focus:border-b-solid placeholder:font-Overpass text-xs focus:outline-none"
                          />
                        <i
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-line cursor-pointer"
                          onClick={ChangePasswordHideShow}
                        >
                          {isPasswordHideShow ? (
                            <VscEye className="w-6 text-gray-800 " />
                          ) : (
                            <VscEyeClosed className="w-6 text-gray-800 " />
                          )}
                        </i>
                      </div>

                      {errors.password && (
                        <span className="text-red-500 block mt-1 text-Poppins text-xs">
                          {`${errors.password.message}`}
                        </span>
                      )}

                      
                      
                      </div>
</div>
              
                  

                      <button
                        className="font-Overpass text-xs w-40  font-semibold mt-10 h-12 bg-[#c8ccc7] text-white  p-2 rounded-lg "
                        type="submit"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
